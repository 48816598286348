<template lang="pug">
  .contacts-drawer
    a.btn-icon-link(@click="uiClick()")
      a-icon.icon(type="unordered-list")
      
    a-drawer(
      width="285"
      title="Quick View Drawer"
      placement="right"
      :closable="false"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="onClose")
</template>

<script>
export default {
  name: 'Contacts',
  data () {
    return {
      visible: false,
    }
  },
  methods: {
    uiClick() {
      this.visible = true;
    },
    afterVisibleChange(val) {
      console.log('visible', val);
    },
    onClose() {
      this.visible = false;
    },
  }
}
</script>

<style lang="less" scoped>
</style>