<template lang="pug">
  //- div
  //-   pre {{ options }}
  a-menu.p-t-15(
    :mode="options.mode"
    :theme="options.theme"
    :forceSubMenuRender="options.forceSubMenuRender"
    :inlineCollapsed="options.inlineCollapsed"
    :inlineIndent="options.inlineIndent"
    :selectable="options.selectable"
    :selected-keys="options.selectedKeys"
    :open-keys="options.defaultOpenKeys"
    @openChange="handleOpenChange")
    
    template(v-for="item in menus")
      sub-menu(:menu="item" :key="item.key" v-if="item.children && item.children.length")
      menu-item(:menu="item" :key="item.key" v-else)
</template>

<script>
import SubMenu from './SubMenu'
import MenuItem from './MenuItem'

export default {
  name: 'Menu',
  components: {
    'sub-menu': SubMenu,
    'menu-item': MenuItem
  },
  props: {
    options: {
      type: Object
    },
    menus: {
      type: Array
    },
  },
  methods: {
    handleOpenChange(openKeys) {
      this.options.defaultOpenKeys = openKeys
      
      // 只展开单个
      //- const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      //- if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      //-   this.openKeys = openKeys;
      //- } else {
      //-   this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      //- }
    }
  }
}
</script>