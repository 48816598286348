<template lang="pug">
a-dropdown(v-model="visible" :trigger="['click']" overlayClassName="fixed")
  a.btn-icon-link
    a-badge(:dot="notification.dot")
      a-icon.icon(type="bell")
  .notification-center(slot="overlay")
    p Content
    a-button(block) Read all notifications
</template>

<script>
export default {
  name: 'Notification',
  
  data() {
    return {
      visible: false,
      notification: {
        dot: true
      }
    }
  },
}
</script>

<style lang="less" scoped>
.notification-center {
  width: 400px;
  height: 372px;
  margin-top: 10px;
  background:#fff;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  position: relative;
  border-radius:4px; padding: 20px;
}
</style>