<template lang="pug">
a-sub-menu(:key="menu.key" v-bind="$props" v-on="$listeners")
  span(slot="title")
    a-icon(:type="menu.meta.icon" v-if="menu.meta && menu.meta.icon")
    span {{ menu.title }}
    //- {{ menu.key }}
    
  template(v-for="(item, index) in menu.children")
    sub-menu(:menu="item" :key="item.key" v-if="item.children && item.children.length")
    menu-item(:menu="item" :key="item.key" v-else)
</template>

<script>
import { Menu } from 'ant-design-vue'

// import SubMenu from './SubMenu'
import MenuItem from './MenuItem'

export default {
  name: 'SubMenu',
  components: {
    // 'sub-menu': SubMenu,
    'menu-item': MenuItem
  },
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    menu: {
      type: Object
    },
  }
}
</script>