<template lang="pug">
a-layout-sider.sidebar(
  width="250"
  collapsedWidth="70"
  collapsible
  :collapsed="ui.menu.config.collapsed"
  :trigger="null")
  
  header.nav-header
    h1.brand
      router-link.logo(to="/")
        span {{ ui.brand_short.text }}
    //- .m-r-5
    //-   a-icon(type="environment")
      
  .nav-menu(v-if="ui.menu.data.length")
    vc-menu.p-t-15(:menus="ui.menu.data" :options="config")
</template>

<script>
import { mapState } from 'vuex'

import layout from '@/config/layout';
import Menu from '@/components/menu/Menu'

export default {
  name: 'LayoutMenu',
  components: {
    'vc-menu': Menu
  },
  computed: {
    ...mapState({
      ui: state => state.ui
    })
  },
  data() {
    return {
      config: {
        collapsed: layout.layout.menu.collapsed,
        theme: 'dark',
        mode: 'inline',
        forceSubMenuRender: true,
        inlineCollapsed: false,
        inlineIndent: 24,
        selectable: true,
        selectedKeys: [],
        defaultOpenKeys: [],
      },
    }
  },
  methods: {
    menuDetection(menus, route, parent = undefined) {
      menus.map(menu => {
        if (menu.children) {
          this.menuDetection(menu.children, route, menu)
        } else {
          if (menu.url === route.fullPath) {
            if (parent) this.config.defaultOpenKeys.push(parent.key)
            this.config.selectedKeys = [ menu.key ]
          }
        }
      })
    },
    routerHandle() {
      this.config.defaultOpenKeys = []

      const menus = this.ui.menu.data
      const route = this.$route

      //- console.log(menus.map(menu => menu.url), this.$route.fullPath)
      this.menuDetection(menus, route)
    }
  },
  
  watch: {
    "$route.name": {
      handler() {
        this.routerHandle();
      },
      immediate: true,
    },
  }
}
</script>