<template lang="pug">
header.header
  .left.header-inner
    h1.brand
      router-link.logo(to="/")
        span {{ ui.brand_short.text }}
    
    ul.quick-menu.header-middle(v-if="ui.header.quickmenu && ui.header.quickmenu.length")
      li(v-for="(item, index) in ui.header.quickmenu" :key="index")
        template(v-if="item.component")
          component(:is="item.component")
        template(v-else)
          a.btn-icon-link(@click="uiClick(item.icon)")
            a-icon.icon(:type="item.icon")

  .right.header-inner
    .user-profile.header-middle
      span.left.fs-14.m-r-10 {{ user.display_name }}
      a-dropdown.right(:trigger="['click']" placement="bottomRight" overlayClassName="fixed")
        a.avatar.btn-icon-link
          a-avatar.block(:size="32" icon="user" :src="user.avatar ? user.avatar : require('@/assets/avatar.jpg')")
        a-menu(slot="overlay" @click="dropdownClick" style="width:150px;")
          a-menu-item(key="account")
            a-icon.icon.fs-16.m-r-5(type="user")
            span account
          a-menu-item(key="setting")
            a-icon.icon.fs-16.m-r-5(type="setting")
            span setting
          a-menu-divider
          a-menu-item(key="logout")
            span signout
            a-icon.icon.right.fs-15.m-t-5.m-l-5(type="logout")

    .extras.header-middle.m-l-10(v-if="ui.header.extras && ui.header.extras.length")
      template(v-for="(item, index) in ui.header.extras")
        template(v-if="item.component")
          component(:is="item.component")
        template(v-else)
          a.btn-icon-link(@click="uiClick(item.icon)")
            a-icon.icon(:type="item.icon")
</template>

<script>
import { mapState } from 'vuex'

import quickmenuNotification from '@/components/quickmenuNotification'
import quickmenuContacts from '@/components/quickmenuContacts'

export default {
  name: 'LayoutHeader',
  components: {
    'quickmenu-notification': quickmenuNotification,
    'quickmenu-contacts': quickmenuContacts
  },
  computed: {
    ...mapState({
      ui: state => state.ui,
      user: state => state.account,
    })
  },
  data() {
    return {}
  },
  methods: {
    uiClick(icon) {
      switch(icon) {
        case 'global':
          this.language();
          break;
        case 'fullscreen':
          this.fullScreen();
          break;
        case 'menu-unfold':
          this.menuFold();
          break;
        case 'unordered-list':
          this.contactsFold();
          break;
        default:
      }
    },
    language() {
      const h = this.$createElement;
      this.$info({
        title: 'This is a notification message',
        content: h('div', {}, [
          h('p', 'zh-CN 简体中文'),
          h('p', 'zh-HK 繁体中文'),
          h('p', 'en-US 英文'),
          h('p', 'ru-RU 俄语'),
          h('p', 'ja-JP 日文'),
          h('p', 'ko-KR 韩语'),
        ]),
        onOk() {},
      });
    },
    fullScreen() {
      this.fullscreen = !this.fullscreen;

      // F11 只能由用户触发
      var el = document.documentElement
      var rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullScreen
      var wscript
      if (typeof rfs !== 'undefined' && rfs) {
        rfs.call(el)
        return
      }
      if (typeof window.ActiveXObject !== 'undefined') {
        // eslint-disable-next-line
        wscript = new ActiveXObject('WScript.Shell')
        if (wscript) wscript.SendKeys('{F11}')
      }
    },
    menuFold() {
      const menu_collapsed = this.$store.state.ui.menu.config.collapsed;
      this.$store.dispatch('ui/menu_collapsed', !menu_collapsed);
    },
    contactsFold() {
      //- this.$store.getters['system/menupin']
      this.ui.contacts.visible = true;
    },
    
    dropdownClick(e) {
      switch(e.key) {
        //- case 'account':
        //-   this.$router.push({ path: '/user/account' });
        //-   break;
        //- case 'switch':
        //-   this.$store.dispatch('system/signin');
        //-   break;
        //- case 'setting':
        //-   this.$router.push({ path: '/setting' });
        //-   break;
        case 'logout':
          this.logout();
          break;
        default:
          console.log(e)
      }
    },

    logout() {
      if (this.$socket && this.$socket.connected) this.$socket.disconnect();
      //- this.$store.dispatch('system/signout');
      this.$store.dispatch('account/signout');
      this.$router.replace({ path: '/sign' });
    },
  },
  created() {
  }
}
</script>