<template lang="pug">
a-menu-item(:key="menu.key" :title="menu.title" v-bind="$props" v-on="$listeners")
  router-link(:to="menu.url")
    a-icon(:type="menu.meta.icon" v-if="menu.meta && menu.meta.icon")
    span {{ menu.title }}
    //- {{ menu.key }}
</template>

<script>
import { Menu } from 'ant-design-vue'

export default {
  name: 'MenuItem',
  props: {
    ...Menu.Item.props,
    menu: {
      type: Object
    }
  }
}
</script>