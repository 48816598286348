<template lang="pug">
a-layout#app(:class="app_classes")
  vc-layout-menu
  a-layout.container.full-width(data-style="background:#eee;")
    vc-layout-header
    main.wrapper
      router-view
      vc-layout-footer
</template>

<script>
import { mapState } from 'vuex'
import LayoutMenu from './LayoutMenu'
import LayoutHeader from './LayoutHeader'
import LayoutFooter from './LayoutFooter'

//- import vcTaxonomyForm from '@/components/vcTaxonomyForm'

export default {
  name: 'Layout',
  components: {
    'vc-layout-menu': LayoutMenu,
    'vc-layout-header': LayoutHeader,
    'vc-layout-footer': LayoutFooter,

    //- 'vc-taxonomy-form': vcTaxonomyForm
  },
  computed: {
    ...mapState({
      ui: state => state.ui,
      modal: state => state.ui.modal,
      //- scrollTop: state => state.system.scrollTop,
    }),
    app_classes() {
      const classes = [];
      if (this.ui.layout.header.fixed) classes.push('fixed-header');
      if (!this.ui.menu.config.collapsed) classes.push('menu-pin');
      if (this.ui.layout.menu.behind) classes.push('menu-behind');
      if (this.ui.layout.classes) classes.concat(this.ui.layout.classes);

      return classes;
    }
  },
  data() {
    return {}
  },
  methods: {},
  created() {
    //- console.log(this.$refs)
  }
}
</script>

<style lang="less"></style>