<template lang="pug">
footer.footer
  p.copyright.left {{ copyright }} #[strong.logo {{ ui.brand.text }}]. All Rights Reserved.
    span(v-if="ui.footer.links && ui.footer.links.length")
      template(v-for="(item, index) in ui.footer.links")
        router-link(:to="item.url") {{ item.label }}
        span(v-if="index !== ui.footer.links.length - 1") |
  p.right.hint-text Hand-crafted #[span.f-ovo &amp;] Made with #[span(style="color: #dd2e44;") ❤] {{ powered }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LayoutFooter',
  computed: {
    ...mapState({
      ui: state => state.ui,
    }),
    copyright() {
      const date = new Date();
      return `Copyright © ${this.ui.copyright ? this.ui.copyright + ' - ' : ''}${date.getFullYear()}`;
    },
    powered() {
      return this.ui.powered ? `Powered by ${this.ui.powered}` : '';
    }
  }
}
</script>